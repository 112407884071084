import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import RoleBasedGuard from '../guards/RoleBasedGuard';

// ----------------------------------------------------------------------
const AllUsers = [
  { id: 1, label: 'Admin' },
  { id: 2, label: 'Manager' },
  { id: 3, label: 'Field Rep' },
  { id: 4, label: 'Farmer' },
  { id: 5, label: 'Sales Manager' },
  { id: 6, label: 'Director' },
  { id: 7, label: 'Inventory Manager' },
];
// FarmerProfile,
const permission1 = AllUsers.filter((user) => [1, 6, 3, 4, 5].includes(user.id)).map((user) => user.label);
// Farmer Edit, Farmer Delete, Field Representative Edit and Delete,   
const permission2 = AllUsers.filter((user) => [1,3].includes(user.id)).map((user) => user.label);
// New Farmer
const permission3 = AllUsers.filter((user) => [1, 3].includes(user.id)).map((user) => user.label);
// UserList,Soil,crops,Crops(new,edit,Delete),Soil(new,edit,Delete),
const permission4 = AllUsers.filter((user) => [1].includes(user.id)).map((user) => user.label);
// Farmer
const permission5 = AllUsers.filter((user) => [1, 3, 4, 5, 6, 7].includes(user.id)).map((user) => user.label);

export { permission1, permission2, permission3, permission4, permission5 };
// -----------------------------------------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard/app')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: 'farmer-register',
      element: (
        <GuestGuard>
          <FarmerRegister />
        </GuestGuard>
      ),
    },
    { path: 'login-unprotected', element: <Login /> },
    { path: 'register-unprotected', element: <Register /> },
    { path: 'reset-password', element: <ResetPassword /> },
    { path: 'verify', element: <VerifyCode /> },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={permission4}>
                  {' '}
                  <UserList />{' '}
                </RoleBasedGuard>
              ),
            },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        {
          path: 'farmer',
          element: (
            <RoleBasedGuard accessibleRoles={permission5}>
              <Farmer />{' '}
            </RoleBasedGuard>
          ),
        },
        {
          path: 'farmer-profile/:farmerId',
          element: (
            <RoleBasedGuard accessibleRoles={permission1}>
              {' '}
              <FarmerProfile />{' '}
            </RoleBasedGuard>
          ),
        },
        { path: 'field-representative', element: <FieldRepresentative /> },
        { path: 'field-representative-profile/:frId', element: <FieldRepresentativeProfile /> },
        {
          path: 'soil',
          element: (
            <RoleBasedGuard accessibleRoles={permission4}>
              {' '}
              <Soil />{' '}
            </RoleBasedGuard>
          ),
        },
        { path: 'soil-detail/:soilId', element: <SoilDetail /> },
        {
          path: 'crops',
          element: (
            <RoleBasedGuard accessibleRoles={permission4}>
              {' '}
              <Crops />
            </RoleBasedGuard>
          ),
        },
        { path: 'crops-detail/:cropId', element: <CropsDetail /> },
        { path: 'crops-variant/:cropId', element: <CropsVariant /> },
        { path: 'land-holding', element: <LandHolding /> },
        { path: 'all-plotting', element: <AllPlotting /> },

        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'thank-you', element: <ThankYou /> },

        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const FarmerRegister = Loadable(lazy(() => import('../pages/auth/FarmerRegister')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const FarmerProfile = Loadable(lazy(() => import('../pages/dashboard/FarmerProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Farmer = Loadable(lazy(() => import('../pages/dashboard/Farmer')));
const FieldRepresentative = Loadable(lazy(() => import('../pages/dashboard/FieldRepresentative')));
const FieldRepresentativeProfile = Loadable(lazy(() => import('../pages/dashboard/FieldRepresentativeProfile')));
const Soil = Loadable(lazy(() => import('../pages/dashboard/Soil')));
const SoilDetail = Loadable(lazy(() => import('../pages/dashboard/SoilDetail')));
const Crops = Loadable(lazy(() => import('../pages/dashboard/Crops')));
const LandHolding = Loadable(lazy(() => import('../pages/dashboard/LandHolding')));
const AllPlotting = Loadable(lazy(() => import('../pages/dashboard/AllPlotting')));
const CropsDetail = Loadable(lazy(() => import('../pages/dashboard/CropsDetail')));
const CropsVariant = Loadable(lazy(() => import('../pages/dashboard/CropsVariant')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ThankYou = Loadable(lazy(() => import('../pages/ThankYou')));
