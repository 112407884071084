import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, InputAdornment, ClickAwayListener, IconButton } from '@mui/material';
import cssStyles from '../../../utils/cssStyles';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import { useSearched } from '../../../searchContext';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { setSearched } = useSearched();
  const pathname = window.location.pathname && window.location.pathname.split('/').pop();

  const handleOpen = () => {
    setSearchQuery('');
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const search = () => {
    if (searchQuery.trim().length > 0) {
      setSearched(searchQuery);
      localStorage.setItem('searched', JSON.stringify(searchQuery));
      if (pathname !== 'farmer') {
        window.location.href = '/dashboard/farmer';
      }
    }
  };
  const clearSearch = () => {
    setSearchQuery('');
    setSearched('');
    localStorage.removeItem('searched');
    // Add any additional actions here to show all data
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!isOpen && (
          <IconButtonAnimate onClick={handleOpen}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
        )}
        <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder="Search…"
              value={searchQuery}
              onChange={onChange}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              endAdornment={
                searchQuery && (
                  <InputAdornment position="end">
                    <IconButton onClick={clearSearch}>
                      <Iconify icon={'eva:close-circle-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </IconButton>
                  </InputAdornment>
                )
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={search}>
              Search
            </Button>
          </SearchbarStyle>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
