import React, { createContext, useContext, useState, useEffect } from "react";

const SearchContext = createContext({});

export const SearchContextProvider = ({ children }) => {
  const [searched, setSearched] = useState("");

  return (
    <SearchContext.Provider value={{searched,setSearched}}>
      {children}
    </SearchContext.Provider>
  );
};


export const useSearched = () => useContext(SearchContext);