// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import useAuth from '../../../hooks/useAuth';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  farmer: getIcon('ic_farmer'),
  fieldRepresentative: getIcon('ic_field_representative'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  soil: getIcon('ic_soil'),
  crops: getIcon('ic_crops'),
  landholding: getIcon('ic_landholding'),
  plotting: getIcon('ic_blog'),
};

const Role = JSON.parse(localStorage.getItem('userAllData'))?.type_label || 'Admin';

// Define the original navConfig array with all items
const originalNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'farmer', path: PATH_DASHBOARD.farmer, icon: ICONS.farmer },
      { title: 'All Users', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
      { title: 'Field Representative', path: PATH_DASHBOARD.fieldRepresentative, icon: ICONS.fieldRepresentative },
      { title: 'Soil', path: PATH_DASHBOARD.soil, icon: ICONS.soil },
      { title: 'Crops', path: PATH_DASHBOARD.crops, icon: ICONS.crops },
      { title: 'Land Holding', path: PATH_DASHBOARD.landHolding, icon: ICONS.landholding },
      { title: 'All Plotting', path: PATH_DASHBOARD.allPlotting, icon: ICONS.plotting },
    ],
  },
];

// ADMIN = 1, "Admin"
//     MANAGER = 2, "Manager"
//     FIELD_REPRESEENTATIVE = 3, "Field Rep"
//     FARMER = 4, "Farmer"
//     SALES_MANAGER = 5, "Sales Manager"
//     DIRECTOR = 6, "Director"
//     INVENTORY_MANAGER = 7, "Inventory Manager"

// Function to get the allowed items based on the role
const getAllowedItems = (role) => {
  switch (role) {
    case 'Admin':
      return ['Dashboard', 'farmer', 'Soil', 'Crops', 'Land Holding', 'All Plotting']; // All items allowed for Admin
    // case 'Farmer':
    //   return ['Dashboard', 'farmer', 'All Users'];
    case 'Sales Manager':
      return ['Dashboard', 'farmer'];
    case 'Director':
      return ['Dashboard', 'Land Holding', 'Field Representative', 'farmer'];
    case 'Field Rep':
      return ['farmer'];
    case 'Inventory Manager':
      return ['Dashboard'];

    default:
      return []; // No items allowed by default
  }
};

// Filter the navConfig options based on the user's role
const navConfig = originalNavConfig.map((section) => ({
  ...section,
  items: section.items.filter((item) => getAllowedItems(Role).includes(item.title)),
}));

export default navConfig;
